@tailwind base;
@tailwind utilities;
@tailwind components;

@import url('https://fonts.googleapis.com/css2?family=Jersey+25&display=swap');

* {
  @apply font-jersey;
}

body {
  @apply bg-[#E78F81]
}

.azeban-hero {
  clip-path: polygon(50% 0%, 90% 6%, 100% 35%, 100% 70%, 85% 90%, 50% 100%, 9% 95%, 0% 70%, 0% 35%, 10% 15%);

}

.azeban-text {
  clip-path: polygon(50% 0%, 81% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 5% 95%, 0% 70%, 0% 35%, 6% 5%);

}

.floating-text {
  clip-path: polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%);
}